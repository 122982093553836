/*
 * 업무구분 : 업무공통
 * 화면 명 : MSPBC003M
 * 화면 설명 : 나의 활동현황(FC홈)
 */
<template>
  <ur-page-container title="나의 활동현황" class="msp msp-bc-wrap--bgGray home_new" :show-title="true" type="page" action-type="none" ref="activity">
    <div class="msp h100">
      <div class="bottom_open_float bgcolor-1">
        <div class="overflow-scroll-y overflow-hidden-x mt20 h100 pl24 fexTy1">
          <div class="myWork_container">
            <div class="gauge_area" v-if="lv_IsShowGauge" @click="$router.push({name: 'MSPAP100M'})">
              <span class="crTy-bk1 fwb">화이팅!</span>
              <mo-badge class="lightyellow3 sm ml8" text="" shape="status">{{$bizUtil.replaceAll(lv_ChnlQulfNm, '만클럽', '만 Club')}}</mo-badge>
              <!-- 
              <mo-badge class="lightred sm ml8" text="" shape="status">컨설턴트</mo-badge>
              <mo-badge class="lightyellow3 sm ml8" text="" shape="status">30만 Club</mo-badge>
              <mo-badge class="lightyellow3 sm ml8" text="" shape="status">50만 Club</mo-badge>
              <mo-badge class="lightyellow3 sm ml8" text="" shape="status">70만 Club</mo-badge>
              <mo-badge class="lightgreen sm ml8" text="" shape="status">100만 Club</mo-badge>
              <mo-badge class="lightgreen sm ml8" text="" shape="status">150만 Club</mo-badge>
              <mo-badge class="lightblue sm ml8" text="" shape="status">명인</mo-badge>
              <mo-badge class="lightblue sm ml8" text="" shape="status">명인P</mo-badge> 
              -->
              <div class="flex">
                <span class="fs18rem fwb crTy-blue3">{{ lv_CurrentMonth }}월 </span><span class="fs18rem fwb ml4">정예화 달성 목표</span>
                <mo-icon icon="msp-arrow-pre" class="deg--180 ml10">msp-arrow-pre</mo-icon>
              </div>
              <div class="gauge_motion mt20" :class="'ty--' + fn_GetRateRange">
              <!-- 
              <div class="gauge_motion mt20 ty--100"> 달성율 100%일때
              <div class="gauge_motion mt20 ty--80"> 달성율 80%일때
              <div class="gauge_motion mt20 ty--60"> 달성율 60%일때
              <div class="gauge_motion mt20 ty--40"> 달성율 40%일때
              <div class="gauge_motion mt20 ty--20"> 달성율 20%일때
              <div class="gauge_motion mt20 ty--00"> 달성율 00%일때
              -->
                <div class="gauge_bg">
                  <mo-icon icon="msp-home-gauge">msp-home-gauge</mo-icon>
                </div>
                <div class="outer">
                  <div class="inner">
                    <span class="img_ani"></span>
                    <div class="counter txt-center">
                      <div>
                        <span v-if="!['00','100'].includes(fn_GetRateRange)" class="fs18rem fwb crTy-blue3 gauge_count">{{ lv_GaugeCount }}%</span>
                        <span class="fs16rem">{{ fn_GetRateMsg.txt1 }}</span>
                      </div>
                      <span class="fs12rem">{{ fn_GetRateMsg.txt2 }}</span>
                    </div>
                  </div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="260px" height="260px">
                  <defs>
                    <linearGradient id="GradientColor">
                      <stop offset="0%" stop-color="#08eb66" />
                      <stop offset="25%" stop-color="#0042dc" />
                      <stop offset="50%" stop-color="#0042dc" />
                      <stop offset="75%" stop-color="#7960ff" />
                      <stop offset="100%" stop-color="#e91e63" />
                    </linearGradient>
                  </defs>
                  <circle cx="130" cy="130" r="125" stroke-linecap="round" />
                </svg>
                <div class="point">
                  <div class="point2"></div>
                </div>
              </div>
            </div>

            <div v-if="(lv_HomeBannDelParngCust.length && lv_HomeBannDelParngCust[0].delParngCustCnt) || lv_EvtNoticeCount || lv_CntcCustCount" class="today_customer_info mt30">
              <div class="info_top_area">
                <span class="info--tit fs18rem fwb" @click="$router.push({name: 'MSPCM115M'})">오늘의 고객정보</span>
                <div class="info--right">
                  <mo-icon icon="msp-home-customer" @click="fn_OpenCustomerStat">msp-home-more</mo-icon>
                </div>
              </div>

              <div class="info_message_area">
                <div class="info--message" @click="$router.push({name: 'MSPCM217M'})">
                  <template v-if="lv_HomeBannDelParngCust.length && lv_HomeBannDelParngCust[0].delParngCustCnt">
                    <mo-badge class="badge-sample-badge lightred sm" text="" shape="status">삭제예정 고객</mo-badge>
                    <span class="fs16rem ml4">동의 만료예정 {{lv_HomeBannDelParngCust[0].delParngCustCnt}}명이 있습니다.</span>
                  </template>
                  <span v-else class="fs16rem ml4">삭제 예정 고객 보기</span>
                </div>
                <!-- 2024-07-09 이벤트고객 MSPCM124M -> (변경) 활동캘린더 MSPAP320M -->
                <div v-if="lv_EvtNoticeCount === 0" class="info--message" @click="$router.push({name: 'MSPAP320M'})"> 
                  <span class="fs16rem ml4">이벤트 고객 보기</span>
                </div>
                <div v-if="lv_CntcCustCount === 0" class="info--message" @click="$router.push({name: 'MSPCM121M'})">
                  <span class="fs16rem ml4">고객알람(고객접촉정보) 보기</span>
                </div>
                
                <div v-for="(item, idx) in lv_HomeBannIapList" :key="idx" class="info--message" @click="fn_OnClickBannIap(item)">
                  <mo-badge v-if="t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_EVT_NOTICE" class="badge-sample-badge lightpurple sm" text="" shape="status">이벤트 고객</mo-badge>
                  <mo-badge v-else-if="t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_CNTC_CUST" class="badge-sample-badge lightblue sm" text="" shape="status">고객알람(고객접촉정보)</mo-badge>
                  <span class="fs16rem ml4" v-html="fn_GetIapCntntOfHomeBannIapList(item)"></span>
                </div>
                
                <div v-if="lv_CntcCustCount > 2" class="pt10 fexTy1 info--more" @click="$router.push({name: 'MSPCM121M'})">
                  <span class=""><mo-icon icon="msp-home-more" class="home_more">msp-home-more</mo-icon> 더 많은 접촉정보 확인하기</span>
                </div>

              </div>
            </div>

            <div v-if="lv_gldnrActrExist" class="calculate-expand mt30" @click="fn_GoldenRulePageMove">
              <div class="arrow-wrap-mgr">
                <span class="tit-pre-icon mr10"><img src="@/assets/img/icon/home_icon/home_menu49.png" alt=""></span>
                <span class="info--tit fs18rem fwb">나의 활동골든룰</span>
                <mo-icon icon="msp-arrow-pre" class="deg--180 f-end">msp-arrow-pre</mo-icon>
              </div>
            </div>

            <div class="today_customer_info home_myWork mt30" @click="$router.push({name: 'MSPAP105M', params:{gldnrActrExist: lv_gldnrActrExist}})">
              <div class="info_top_area">
                <div class="info--left flex">
                  <span class="info--tit fs18rem fwb">나의활동</span>
                  <mo-icon icon="msp-home-backarrow" class="deg--180 ml10">msp-home-backarrow</mo-icon>
                </div>
                <span class="fs19rem fwb min100 txt-right crTy-white">{{lv_currWeek}}</span>
              </div>
              <div class="myWork_area mt30">
                <div class="item">
                  <span>굿모닝</span>
                  <div class="fexTy1">
                    <span class="fs24rem fwb">{{ lv_currIndx.gmongMtingAtdncNts || 0 }}</span>
                    <span v-if="lv_prevIndx.gmongMtingAtdncNts" class="alram" :class="{'alram--red': lv_prevIndx.gmongMtingAtdncNts > 0}">{{ fn_GetPrevIndxStr(lv_prevIndx.gmongMtingAtdncNts) }}</span>
                  </div>
                </div>
                <div class="item bg_none">
                  <span>귀점</span>
                  <div class="fexTy1">
                    <span class="fs24rem fwb">{{ lv_currIndx.rtnbsAftActNts || 0 }}</span>
                    <span v-if="lv_prevIndx.rtnbsAftActNts" class="alram" :class="{'alram--red': lv_prevIndx.rtnbsAftActNts > 0}">{{ fn_GetPrevIndxStr(lv_prevIndx.rtnbsAftActNts) }}</span>
                  </div>
                </div>
                <div class="item">
                  <span>필수컨설팅</span>
                  <div>
                    <span class="fs24rem fwb">{{ lv_currIndx.mndtCnstgCustFgr || 0 }}</span>
                    <span v-if="lv_prevIndx.mndtCnstgCustFgr" class="alram" :class="{'alram--red': lv_prevIndx.mndtCnstgCustFgr > 0}">{{ fn_GetPrevIndxStr(lv_prevIndx.mndtCnstgCustFgr) }}</span>
                  </div>
                </div>
                <div class="item bg_none">
                  <span>필수컨설팅<br />(신규)</span>
                  <div class="fexTy1">
                    <span class="fs24rem fwb">{{ lv_currIndx.newMndtCnstgCustFgr || 0 }}</span>
                    <span v-if="lv_prevIndx.newMndtCnstgCustFgr" class="alram" :class="{'alram--red': lv_prevIndx.newMndtCnstgCustFgr > 0}">{{ fn_GetPrevIndxStr(lv_prevIndx.newMndtCnstgCustFgr) }}</span>
                  </div>
                </div>
                <div class="item">
                  <span>통합<br />보장설계</span>
                  <div>
                    <span class="fs24rem fwb">{{ lv_currIndx.untyCovrPlanCustFgr || 0 }}</span>
                    <span v-if="lv_prevIndx.untyCovrPlanCustFgr" class="alram" :class="{'alram--red': lv_prevIndx.untyCovrPlanCustFgr > 0}">{{ fn_GetPrevIndxStr(lv_prevIndx.untyCovrPlanCustFgr) }}</span>
                  </div>
                </div>
                <div class="item bg_none">
                  <span>모바일<br />보장설계</span>
                  <div>
                    <span class="fs24rem fwb">{{ lv_currIndx.moblCovrPlanCustFgr || 0 }}</span>
                    <span v-if="lv_prevIndx.moblCovrPlanCustFgr" class="alram" :class="{'alram--red': lv_prevIndx.moblCovrPlanCustFgr > 0}">{{ fn_GetPrevIndxStr(lv_prevIndx.moblCovrPlanCustFgr) }}</span>
                  </div>
                </div>
              </div>
              <div class="mt30">
                <span class="crTy-white fs12rem bul-white">보장설계 주차별 활동현황 : 중복고객 포함</span>
              </div>
            </div>

            <div class="home_info_area mt30">
              <div class="info--tit flex" @click="$router.push({name: 'MSPSA201M'})">
                <span class="fs18rem fwb">최신 안내자료</span>
                <mo-icon icon="msp-arrow-pre" class="deg--180 ml10">msp-arrow-pre</mo-icon>
              </div>

              <mo-list :list-data="lv_RntCntntItems" class="info_list mt20">
                <template #list-item="{item}">
                  <mo-list-item class="info_view">
                    <div class="" @click="fn_OnSelectedSingleItem(item)">
                      <div>
                        <img :src="item.blobImgUrl">
                        <mo-badge class="lightblue sm mt10" shape="status">{{item.untySlmtClsfNm01}}</mo-badge>
                        <span class="mt4 fs16rem txtSkip3">{{item.untySlmtNm}}</span>
                      </div>
                    </div>
                  </mo-list-item>
                </template>
              </mo-list>
            </div>
            <div class="mt80"></div>
          </div>
        </div>
      </div>
    </div>
    <mo-bottom-sheet ref="customerStat" :close-btn="false"  class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        고객현황
        <div class="ns-btn-close" @click="$refs.customerStat.close()" name="닫기"></div>
      </template>
      <div class="fex-rw content-area mhAuto txt-center customer-stats-wrap">
        <span>
          <h6 class="crTy-bk7 fs16rem">고객 수</h6>
          <p class="mt0 mb0 crTy-blue3"><b>{{ cMAllCustCntSVO.cmTot }}</b>명</p>
        </span>
        <span>
          <h6 class="crTy-bk7 fs16rem">본인</h6>
          <p class="mt0 mb0 crTy-blue3"><b>{{ cMAllCustCntSVO.cm03 }}</b>명</p>
        </span>
        <span>
          <h6 class="crTy-bk7 fs16rem">관심</h6>
          <p class="mt0 mb0 crTy-blue3"><b>{{ cMAllCustCntSVO.cm05 }}</b>명</p>
        </span>
        <span>
          <h6 class="crTy-bk7 fs16rem">가망(백지)</h6>
          <p class="mt0 mb0 crTy-blue3"><b>{{ cMAllCustCntSVO.cm00 }}</b>명</p>
        </span>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" @click="$refs.customerStat.close()" class="ns-btn-round white">확인</mo-button>
            <mo-button componentid="" color="primary" shape="border" size="medium" @click="$router.push({name: 'MSPCM115M'})" class="ns-btn-round blue">전체고객</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <mo-bottom-sheet ref="print_bottomSheet"  class="ns-bottom-sheet closebtn">
      <template v-slot:title>
      인쇄
        <div class="ns-btn-close" @click="fn_print_BottomSheet_close()" name="닫기"></div>
      </template>

      <ur-box-container direction="column" alignV="start">        
        <ur-box-container componentid="namecard">          
          <ur-box-container componentid="" direction="row" class="fexTy5 pl24 mb30">
            <span class="mr35">매수</span>
                <mo-stepper v-model="lv_SpinValue" :value=1  :min="1" :max="20"/>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>

        <template v-slot:action>
        <ur-box-container align-v="start" componentid="" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs pt0">
          <div class="relative-div">
            <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_print_BottomSheet_close()">취소</mo-button>
                <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OzConfirmBtnClicked()">인쇄</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

  </ur-page-container>
</template>

<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import MenuConst from '@/ui/sp/MenuConstans.js'
import SpConstants from '@/config/constants/spConstants'
import moment from 'moment'
import DateUtil from '@/ui/sp/common/exDateUtils'
import MSPSA203P from '@/ui/sa/MSPSA203P.vue'
import MSPSA210P from '@/ui/sa/MSPSA210P.vue'
import MSPSA222P from '@/ui/sa/MSPSA222P.vue'
import MSPSA063P from '@/ui/sa/MSPSA063P.vue'
import MSPCM128P from '@/ui/cm/MSPCM128P.vue'
import MSPCM152P from '@/ui/cm/MSPCM152P.vue'
import MSPCM154P from '@/ui/cm/MSPCM154P.vue'
import MSPCM191P from '@/ui/cm/MSPCM191P.vue'
import MSPCM192P from '@/ui/cm/MSPCM192P.vue'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPBC003M',
  screenId: 'MSPBC003M',
  components: {},
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    //골든룰활동 컨설턴트 활동룰 포함 현황 조회
    this.fn_gldnrActrExist()
  },
  mounted() {
  },
  activated() {

    // 현재 활동성과 조회
    this.lv_IsShowGauge && this.fn_SelThmmActActul()

    // 시스템 현재일자 조회
    // this.$commonUtil.getServerTime().then(response => {
      // 시스템 현재일자 설정
      // this.lv_SystemToday = moment(response).format('YYYYMMDD')
      // 사랑온 미니 홈배너용 삭제예정고객 조회
      this.fn_SelDeltParngCustCnt()
    // })

    // 사랑온 미니 홈배너용 이벤트고객 및 접촉고객 알림목록 조회
    this.fn_SelListHomeBannIapForMSP()

    // 활동현황확인조회
    this.fn_SelListActIndx()

    //통합영업자료사용권한코드
    this.fn_SetUntySlmtUseAuthCd()
    // 최신자료 로딩
    this.fn_SelRntCntnt()

    // scroll 위치 복원
    this.fn_ScrollBefore()
  },
  deactivated() {
    // scroll 위치 저장
    this.beforeScrollTop = this.$refs.activity.beforeScroll
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    this.MenuConst = MenuConst
    return {
      lv_CurrentMonth: moment().format('MM'),
      lv_ChnlQulfNm: '',
      lv_IsShowGauge: !['BK','DI','DS','FS','GA','JK'].includes(this.getStore('userInfo').getters.getUserInfo.srvcMngScCd),
      lv_GaugeCount: 0,
      lv_APThmmActActulSVO: {},
      lv_ActualRate: 0,
      cMAllCustCntSVO: {
        cm00: 0,
        cm01: 0,
        cm02: 0,
        cm03: 0,
        cm04: 0,
        cm05: 0,
        cmTot: 0,
      },
      lv_HomeBannDelParngCust: [], // 홈배너 삭제예정고객 LIST
      lv_HomeBannIapList: [], // 홈배너알림(이벤트고객, 접촉고객) List
      lv_CntcCustCount: 0, // 고객접촉정보 수
      lv_EvtNoticeCount: 0, // 이벤트고객 수
      lv_prevIndx: {},
      lv_currIndx: {},
      lv_currWeek: '',
      lv_RntCntntItems: [], // 최신 자료 목록

      // 공용 선택된 아이템 리스트, child의 리스트들과 동기화 됨
      lv_SelectedItemList: [],
      lv_SelectedItemListTmp:[],
      lv_Popup210: {},  // 나의보관함추가popup    
      lv_Popup222: {},  // 화상상담자료저장poppup
      lv_Popup152: {},  // 수신고객목록
      lv_Popup128: {},  // 안내수신고객목록
      lv_Popup154: {},  // 터치온고객문자발송 
      lv_Popup191: {},  // 이메일수신고객목록
      lv_Popup192: {},  // 터치온고객이메일발송 
      isMSPSA063P: {}, //  화상상담자료조회popup
      lv_TargetType: 'c',
      lv_SelectedCustList: [], // 화상상담자료저장 고객목록 리스트
      lv_SpinValue: 1,            // 인쇄매수    
      lv_IsIncludeCnsltInfo: 'Y', // 컨설턴트 개인정보 이미지 포함 여부
      isNofacCnsl: false,  // 비대명 상상 관련 
      lv_Class: false,        //클래스 함수지정 
      lv_gldnrActrExist: false  //골든룰 유저 확인
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    fn_GetUserInfo() {
      return this.getStore('userInfo').getters.getUserInfo
    },
    fn_GetRateRange() {
      const rate = this.lv_ActualRate
      return rate >= 100 ? '100'
           : rate >= 80  ? '80'
           : rate >= 60  ? '60'
           : rate >= 40  ? '40'
           : rate >= 20  ? '20'
           : '00'
    },
    fn_GetRateMsg() {
      const rate = this.lv_ActualRate
      return rate >= 100 ? {txt1:'정예화달성', txt2:'축하드려요!!'}
           : rate >= 80  ? {txt1:'정예화달성', txt2:'거의 다 왔어요'}
           : rate >= 60  ? {txt1:'정예화달성', txt2:'정예화달성 잘 하고 있어요'}
           : rate >= 40  ? {txt1:'정예화달성', txt2:'조금만 더 힘내세요~'}
           : rate >= 20  ? {txt1:'정예화달성', txt2:'도전 차근차근 가볼까요?'}
           : {txt1:'정예화달성을 위해', txt2:' 도전 시작!'}
    },
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_SelThmmActActul
    * 설명       : 현재 활동성과 조회
    ******************************************************************************/
    fn_SelThmmActActul() {
      const pParams = {
        cnsltNo: this.fn_GetUserInfo?.userId,
        strYmd: moment(moment().subtract(30, 'day')).format('YYYYMMDD'),
        endYmd: this.$bizUtil.getDateToString(new Date(), '')
      }
      const trnstId = 'txTSSAP00S9'
      this.post(this, pParams, trnstId, 'S').then(response => {
        
        if (response?.body) {
          // 현재 활동성과 조회
          if ( response.body.rsltCd === 'S') {
            this.lv_APThmmActActulSVO = response.body

            // AFC 이면서 무급일 경우 컨설턴트로 표기 ( 정지웅 요청 : 2019.02.25 )
            if ( ['AM','FS'].includes(this.fn_GetUserInfo?.srvcMngScCd) && this.lv_APThmmActActulSVO.chnlQulfNm === '무급') {
              this.lv_ChnlQulfNm = '컨설턴트'
            } else {
              this.lv_ChnlQulfNm = this.lv_APThmmActActulSVO.chnlQulfNm
            }

            // 실적 달성율 = ( 실적 / 목표금액 ) * 100
            this.lv_ActualRate = Math.round(((Number(this.lv_APThmmActActulSVO.avlPrfmAmt1) / Number(this.lv_APThmmActActulSVO.qulfClbyStndAmt)) || 0) * 100) //실적
            this.fn_AnimateCounter()
            
          } else {
            this.$addSnackbar(response.body.rsltMsg)
          }
        } else {
          this.$addSnackbar(response?.msgComm?.msgDesc)
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    fn_AnimateCounter() {
      const interval = setInterval(() => {
        if (this.lv_GaugeCount >= this.lv_ActualRate) {
          clearInterval(interval)
          return
        }
        this.lv_GaugeCount++
      }, this.fn_GetRateRange === '100' ? 10
       : this.fn_GetRateRange === '80' ? 20
       : this.fn_GetRateRange === '60' ? 30
       : this.fn_GetRateRange === '40' ? 40
       : 50
      )
    },
   /******************************************************************************
    * Function명 : fn_OpenCustomerStat
    * 설명       : 전체 고객 수 조회
    ******************************************************************************/
    fn_OpenCustomerStat() {
      const trnstId = 'txTSSCM33S8'
      const auth = 'S'
      var lv_Vm = this
      const pParams = {
        cnsltNo: this.fn_GetUserInfo?.userId
      }
      this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
        // 서버 데이터 가져오기
        if (response?.body) {
          const responseList = response.body.cMAllCustCntSVO

          if (responseList) {
            const result = {}
            result.cmTot = 0
            for (let i = 0; i < responseList.length; i++) {
              if ( responseList[i].custScCd === '01' ) {
                result.cm01 = parseInt(responseList[i].smtotCnt.trim())
                result.cmTot = result.cmTot + result.cm01
              } else if ( responseList[i].custScCd === '02' ) {
                result.cm02 = parseInt(responseList[i].smtotCnt.trim())
                result.cmTot = result.cmTot + result.cm02
              } else if ( responseList[i].custScCd === '03' ) {
                result.cm03 = parseInt(responseList[i].smtotCnt.trim())
                result.cmTot = result.cmTot + result.cm03
              } else if ( responseList[i].custScCd === '04' ) {
                result.cm04 = parseInt(responseList[i].smtotCnt.trim())
                result.cmTot = result.cmTot + result.cm04
              } else if ( responseList[i].custScCd === '05' ) {
                result.cm05 = parseInt(responseList[i].smtotCnt.trim())
                result.cmTot = result.cmTot + result.cm05
              } else if ( responseList[i].custScCd === '00' ) {
                result.cm00 = parseInt(responseList[i].smtotCnt.trim())
              }
            }
            lv_Vm.cMAllCustCntSVO = result
          }

          // 고객현황 바텀시트 오픈
          lv_Vm.$refs.customerStat.open()
        } else {
          lv_Vm.$addSnackbar(response?.msgComm?.msgDesc)
        }
      })
      .catch(function (error) {
        window.vue.error(error)
      })
    },
    /******************************************************************************
    * Function명  : fn_SelDeltParngCustCnt ()
    * 설명        : 홈화면 배너용 삭제예정고객 수 조회
    * Params      : N/A
    * Return      :
    ******************************************************************************/
    fn_SelDeltParngCustCnt () {
      let lv_Vm = this
      let pParams = {}
      const trnstId = 'txTSSBC41S4'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth, {}, true).then(function (response) {
        if (response?.body) {
          // let t_DelParngCustCnt = 0
          if ( response.body.iCDeltParngCustVO?.length ) {
            // 삭제예정고객List
            /*
            let t_ICDeltParngCustVO = response.body.iCDeltParngCustVO
            let t_Today = lv_Vm.lv_SystemToday // 시스템 현재일자
              
            // 삭제예정고객 List에서 필수컨설팅동의 종료예정일 또는 마케팅동의 종료예정일이 2주 이내 인 건을 추출
            for ( let i=0; i < t_ICDeltParngCustVO.length; i++ ) {
              let t_MndtCnstgCnsntEndYmd = t_ICDeltParngCustVO[i].mndtCnstgCnsntEndYmd // 필수컨설팅동의 종료예정일
              let t_MktCnsntEndYmd = t_ICDeltParngCustVO[i].mktCnsntEndYmd// 마케팅동의 종료예정일
              
              // 필수컨설팅동의 종료예정일이 2주(14일) 이내 인지 check
              let t_IntervalDate = -1
              if ( t_MndtCnstgCnsntEndYmd !== undefined && t_MndtCnstgCnsntEndYmd !== null &&
                t_MndtCnstgCnsntEndYmd !== '' && t_MndtCnstgCnsntEndYmd !== '00000000' ) {
                t_IntervalDate = lv_Vm.fn_CalcIntervalDate(t_Today, t_MndtCnstgCnsntEndYmd)
                console.log('필수컨설팅동의 확인 ::', t_Today, t_MndtCnstgCnsntEndYmd, t_IntervalDate)

                if ( Number(t_IntervalDate) >= 0 && Number(t_IntervalDate) <= 14 ) {
                  t_DelParngCustCnt++
                  continue
                }
              }

              // 마케팅동의 종료예정일이 2주(14일) 이내 인지 check
              t_IntervalDate = -1
              if ( t_MktCnsntEndYmd !== undefined && t_MktCnsntEndYmd !== null &&
                t_MktCnsntEndYmd !== '' && t_MktCnsntEndYmd !== '00000000' ) {
                t_IntervalDate = lv_Vm.fn_CalcIntervalDate(t_Today, t_MktCnsntEndYmd)
                console.log('마케팅동의 확인 ::', t_Today, t_MktCnsntEndYmd, t_IntervalDate)

                if ( Number(t_IntervalDate) >= 0 && Number(t_IntervalDate) <= 14 ) {
                  t_DelParngCustCnt++
                  continue
                }
              }
            }
            if ( t_DelParngCustCnt > 0 ) {
              lv_Vm.lv_HomeBannDelParngCust = [{
                'delParngCustCnt': t_DelParngCustCnt
              }]
            }
            */
            lv_Vm.lv_HomeBannDelParngCust = [{
              'delParngCustCnt': response.body.iCDeltParngCustVO.length
            }]
          }
        }
      })
      .catch(function (error) {
        window.vue.error(error)
      })
    },
    /******************************************************************************
    * Function명  : fn_SelListHomeBannIapForMSP ()
    * 설명        : 사랑온 미니 홈배너용 이벤트고객 및 접촉고객 알림목록 조회(당일 기준 수신 미확인건)
    * Params      : N/A
    * Return      : 이벤트고객 및 접촉고객 당일기준 미확인 알림목록
    ******************************************************************************/
    fn_SelListHomeBannIapForMSP () {
      const pParams = {
        nextKey: '1',
        readCount: 6
      }
      const trnstId = 'txTSSBC03SC'
      const auth = 'S'
      this.post(this, pParams, trnstId, auth).then(response => {
        if (response?.body?.returnList) {
          // 조회된 결과 배너 설정
          this.lv_CntcCustCount = 0
          this.lv_EvtNoticeCount = 0
          this.lv_HomeBannIapList = response.body.returnList.filter(item => {
            const t_IapKndCd = item.iapKndCd
            // 알림종류코드가 접촉고객(2)인 경우: 최대 2개 표시
            if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_CNTC_CUST ) {
              this.lv_CntcCustCount++
              return this.lv_CntcCustCount <= 2
            }

            // 알림종류코드가 이벤트고객(9)인 경우: 최대 1개 표시
            if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_EVT_NOTICE ) {
              this.lv_EvtNoticeCount++
              return this.lv_EvtNoticeCount <= 1
            }
          })
        }
      })
      .catch(function (error) {
        window.vue.error(error)
      })
    },
    /******************************************************************************
    * Function명  : fn_GetIapCntntOfHomeBannIapList ()
    * 설명        : 홈배너용 알림목록 중 알림내용을 return하는 함수
    * Params      : item
    * Return      : 홈배너용 알림목록 중 알림내용
    ******************************************************************************/
    fn_GetIapCntntOfHomeBannIapList (item) {
      let t_RtnTxt = item.iapCntnt

      const t_IapKndCd = item.iapKndCd
      // 알림종류코드가 접촉고객(2)인 경우 '[접촉정보]' 문구삭제
      if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_CNTC_CUST ) {
        t_RtnTxt = t_RtnTxt.replace('[접촉정보]', '').trim()
      }

      // 알림종류코드가 이벤트고객(9)인 경우 '[활동캘린더]' 문구삭제 및 조정
      if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_EVT_NOTICE ) {
        t_RtnTxt = t_RtnTxt.replace('[활동캘린더]', '').trim()

        // t_RtnTxt = t_RtnTxt.replace('금일 ', '').trim()

        // t_RtnTxt = t_RtnTxt.replace('이 있습니다.', '입니다.').trim()
        
        // t_RtnTxt = '<b>' + t_RtnTxt.replace('이 있습니다.', '</b>이 있습니다.').trim()

        // 고객이벤트 알림인 경우 추가메시지 설정
        // t_RtnTxt = t_RtnTxt + ' 고객님께 연락해보는건 어떨까요?'
      }
      return t_RtnTxt
    },
    fn_OnClickBannIap(item) {
      const t_IapKndCd = item.iapKndCd
      // 알림종류코드가 접촉고객(2)인 경우
      if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_CNTC_CUST ) {
        this.$router.push({name: 'MSPCM121M'})  
      }

      // 알림종류코드가 이벤트고객(9)인 경우
      if ( t_IapKndCd === MenuConst.PUSH_ALARM_TYPE_EVT_NOTICE ) {
        this.$router.push({name: 'MSPAP320M'})  // 2024-07-09 이벤트고객 MSPCM124M -> (변경) 활동캘린더 MSPAP320M
      }
    },
    /******************************************************************************
    * Function명 : fn_SelListActIndx
    * 설명       : 활동현황확인조회
    ******************************************************************************/
    fn_SelListActIndx() {
      const pParams = {}
      const trnstId = 'txTSSBC40S8'
      this.post(this, pParams, trnstId, 'S').then(response => {
        if (response?.body?.icactIndxnVO) {
          const icactIndxnVO = response.body.icactIndxnVO
          this.lv_prevIndx = icactIndxnVO.find(item => item.indxScCd === 'D')
          this.lv_currIndx = icactIndxnVO.find(item => item.indxScCd !== 'D')
          this.lv_currWeek = `${Number(response.body.stndYm?.substr(4,2))}월${this.lv_currIndx.indxScCd}주차`
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    fn_GetPrevIndxStr(val) {
      return val > 0 ? `+${val}`
           : val < 0 ? `-${val}`
           : val
    },
    /*********************************************************
     * Function명: fn_SetUntySlmtUseAuthCd
     * 설명: 통합영업자료사용권한코드 설정
     *********************************************************/
    fn_SetUntySlmtUseAuthCd() {
      let chnlScCd = this.getStore('userInfo').getters.getUserInfo.srvcMngScCd
      console.log('chnlScCd :::', chnlScCd)

      switch (chnlScCd) {
        case 'FC':
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_FC
          break
        case 'AM':
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_AM
          break
        case 'SA':
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_SA
          break
        case 'GP':
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_GP
          break
        case 'BS':
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_BS
          break
        case 'DI':
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_DI
          break
        case 'GA':
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_GA
          break
        default:
          this.lv_UntySlmtUseAuthCd = SpConstants.UNTYSLMT_USERAUTH_CD_FC
          break
      }
    },
    /*********************************************************
     * Function명: fn_SelRntCntnt
     * 설명: 최신자료 최초 조회
     * Params: N/A
     * Return: 최신자료 컨텐츠 목록
     **********************************************************/
    fn_SelRntCntnt() {
      let lv_vm = this

      let pParams =
      {
        untySlmtUseAuthCd: lv_vm.lv_UntySlmtUseAuthCd, // 통합영업자료사용권한코드(전체/FC/AM/LT/GFC/방카)
        whlInqrYn: 'Y', // 전체조회여부
        moblInqrYn: 'Y' // 모바일에서 호출여부
      }

      // 트랜잭션ID 설정
      let trnstId = 'txTSSSA01S1'
      const _auth = 'S'

      this.post(lv_vm, pParams, trnstId, _auth)
        .then(function (response) {
          // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
          if (lv_vm.$commonUtil.isSuccess(response)) {
            // 서비스 후처리
            if (response.body !== null && response.body !== '' && response.body.iCCntntBasInfoVO !== null && response.body.iCCntntBasInfoVO.length > 0) {
              // 서비스 호출 결과(body)가 있을 경우
              // 응답 데이터 처리
              let reponseBody = response.body
              reponseBody.iCCntntBasInfoVO.forEach(async (item, index) => {

                item.blobImgUrl = ''
                if (process.env.NODE_ENV === 'local') {
                  item.blobImgUrl = require('@/assets/img/list/img-' + (Math.floor(Math.random() * 3) + 1) + '.png')
                } else if (process.env.NODE_ENV === 'development') {
                  item.blobImgUrl = '/filedown.do?path=UNTY_SLMT_DIR&filename=/img_1.png'
                } else {
                  let responseObj = null
                  if (item.thumApndFilePathNm !== null && item.thumApndFilePathNm !== '' && item.thumApndFileStoreNm !== null && item.thumApndFileStoreNm !== '') {
                    let strUrl = '/filedown.do?path=UNTY_SLMT_DIR&filename=' + item.thumApndFilePathNm + '/' + item.thumApndFileStoreNm
                    responseObj = await lv_vm.$bizUtil.httpUrlCall(strUrl)
                  }
                  if (responseObj) {
                    item.blobImgUrl = responseObj
                  } else {
                    item.blobImgUrl = require('@/assets/img/list/img-' + (Math.floor(Math.random() * 3) + 1) + '.png')
                  }
                }
              })
              // lv_vm.$data.msgTxt = reponseBody.iCCntntBasInfoVO
              const rntCntntBasInfoVOList = reponseBody.iCCntntBasInfoVO
              // 서비스 통신 조회키
              // lv_vm.lv_rntCntntStndKeyList[0] = response.trnstComm.stndKeyList[0]
              // 검색 후, 전체 데이터를 다시 보여주기 위해 저장 , 이때 7개만 보여준다.
              lv_vm.lv_RntCntntItems = rntCntntBasInfoVOList.slice(0, 7)

              console.log('++++++최신자료 목록++++++', lv_vm.lv_RntCntntItems)
    
            } else {
              // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
            }
          } else {
            // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            lv_vm.$addSnackbar('[' + response?.msgComm?.msgCd + ']\n' + response?.msgComm?.msgDesc)
          }
        }, function (error) {
          // 서버 에러 처리
          return Promise.reject(error)
        })
        .catch(function (error) {
          window.vue.error('TSSSP201M_fn_onInitRntCntnt ERROR: ' + error)
        })
    },
    /*********************************************************
    * Function명: fn_OnSelectedSingleItem
    * 설명: 선택된 인기자료/최신자료/내가사용한자료의 제목부분을
            클릭한경우 상세보기 팝업창을 띄우지 않고 바로 해당 pdf뷰
            를 보여준다.
    * Params: target
    * Return: N/A
    *********************************************************/
    fn_OnSelectedSingleItem (pSelectedItem) {
      this.clickedSingleItem = true
      //pSelectedItem.checked = false
      this.lv_SelectedSingleItem = pSelectedItem
      this.lv_SelectedItemList = []
      this.lv_SelectedItemList.push(pSelectedItem)
      //this.$bizUtil.showCustomBottomSheet(this, this.$refs.bs10, false)

      // 단일 선택 후 상세보기 진행 시
      this.lv_IsMadeMultiItem = false
      console.log(this.lv_IsMadeMultiItem)
      if (pSelectedItem.vdoLnkUrl !== null && pSelectedItem.vdoLnkUrl !== undefined && pSelectedItem.vdoLnkUrl !== '') {
        // 동영상 링크일 경우 해당 경로를 이용해 브라우저 호출
        this.fn_IncrementItemInqrFgrList()
      } else if (pSelectedItem.pdfFileYn === 'Y') {
        this.fn_IncrementItemInqrFgrList()
      } else {
        // 동영상 링크가 아닐 경우는 인쇄 실행
        this.fn_CreateToPDFContnt()
      }
//
      // FOM 파일 저장
      if (!this.lv_SelectedFomItemList) this.lv_SelectedFomItemList = []
      this.lv_SelectedFomItemList.push(pSelectedItem)
    },
    /******************************************************************************
    * Function명 : fn_IncrementItemInqrFgrList
    * 설명       : 조회 이력 저장
    * Params     : N/A
    * Return     : N/A
    ******************************************************************************/
    fn_IncrementItemInqrFgrList () {
      let lv_vm = this
      const trnstId = 'txTSSSA01P2'
      const auth = 'S'

      let sParams = {}
      // 컨설턴트 번호
      sParams.cnsltNo = lv_vm.getStore('userInfo').getters.getUserInfo.userId
      if (lv_vm.lv_IsMadeMultiItem) {
        sParams.sACntntBasInfoSVO = lv_vm.lv_SelectedItemList
      } else {
        let tempVOList = []
        tempVOList.push(lv_vm.lv_SelectedSingleItem)
        sParams.sACntntBasInfoSVO = tempVOList
      }

      this.post(lv_vm, sParams, trnstId, auth)
        .then(function (response) {
        // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
          lv_vm.getStore('progress').getters.getState.isShow = true
          if (lv_vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '') {

              if (response.body.sASelCntntDtlSVO != null && response.body.sASelCntntDtlSVO.length > 0) {
                if (response.body.sASelCntntDtlSVO.length === sParams.sACntntBasInfoSVO.length) {
                  // 선택 파일이 단일 파일이면서, 동영상 파일일 경우는 외부 링크 연결, 그렇지 않을 경우는 PDF Viewer 실행
                  if (!lv_vm.lv_IsMadeMultiItem && lv_vm.lv_SelectedSingleItem.vdoLnkUrl !== null && lv_vm.lv_SelectedSingleItem.vdoLnkUrl !== undefined && lv_vm.lv_SelectedSingleItem.vdoLnkUrl !== '') { // 동영상 링크일 경우 해당 경로를 이용해 브라우저 호출
                    // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                    lv_vm.getStore('progress').getters.getState.isShow = false
                    window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_vm.lv_SelectedSingleItem.vdoLnkUrl}, () => {}, () => {})
                  } else if (lv_vm.lv_IsMadeMultiItem && lv_vm.lv_SelectedItemList.length === 1 && lv_vm.lv_SelectedItemList[0].vdoLnkUrl !== null && lv_vm.lv_SelectedItemList[0].vdoLnkUrl !== undefined && lv_vm.lv_SelectedItemList[0].vdoLnkUrl !== '') {
                    // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                    lv_vm.getStore('progress').getters.getState.isShow = false
                    window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_vm.lv_SelectedItemList[0].vdoLnkUrl}, () => {}, () => {})
                  } else {
                    lv_vm.fn_SaveFOMFile()
                  }
                }
              }
            } else {
              // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
              lv_vm.getStore('progress').getters.getState.isShow = false
              if (response?.msgComm?.msgCd !== null && response?.msgComm?.msgCd.length > 0) {
                lv_vm.$addSnackbar('[' + response?.msgComm?.msgCd + ']\n' + response?.msgComm?.msgDesc)
              }
            }
          }
        })
        .catch(function (error) {
          // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
          lv_vm.getStore('progress').getters.getState.isShow = false
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_CreateToPDFContnt
    * 설명       : 컨설턴트 정보 조회(전문 생성을 위해)
    * 인자       : lv_UserInfo 사용자 정보 데이터 (Object)
    * Return     : void
    ******************************************************************************/
    fn_CreateToPDFContnt () {
      let lv_vm = this

      lv_vm.lv_SADCFmlmtSVOList = []
      lv_vm.lv_CompletedPDFFileVOList = []

      let pParams = {}
      pParams.cnsltNo = this.getStore('userInfo').getters.getUserInfo.userId

      if (lv_vm.lv_IsMadeMultiItem) {
        // FOM 파일만 PDF 생성 대상
        pParams.sACntntBasInfoSVO = lv_vm.lv_SelectedFomItemList
      } else {
        let tempVOList = []
        tempVOList.push(lv_vm.lv_SelectedSingleItem)
        pParams.sACntntBasInfoSVO = tempVOList
      }
      const trnstId = 'txTSSSA22P3'
      const auth = 'S'
      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
          if (lv_vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '' && response.body.sADCFmlmtSVO != null && response.body.sADCFmlmtSVO.length > 0) {
              // 서비스 호출 결과(body)가 있을 경우
              // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
              //lv_vm.getStore('progress').getters.getState.isShow = true
              lv_vm.lv_SADCFmlmtSVOList = response.body.sADCFmlmtSVO
              lv_vm.lv_CheckDocsRetryNumberOfTimes = 0 // PDF 생성여부 확인 조회 회수 초기화
              lv_vm.fn_CheckContentsTransformation(lv_vm.lv_SADCFmlmtSVOList)
            } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_vm.$addSnackbar('[' + response?.msgComm?.msgCd + ']\n' + response?.msgComm?.msgDesc)
            }
          } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            lv_vm.$addSnackbar('[' + response?.msgComm?.msgCd + ']\n' + response?.msgComm?.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_CheckContentsTransformation  ( 2차 개발시 메서드 대체 처리 )
    * 설명       : FOM 파일을 PDF 파일로 변환 요청 후 요청한 PDF 생성 여부 확인
    * Params     : 변환 요청한 결과 VO 목록
    * Return     : N/A
    ******************************************************************************/
    fn_CheckContentsTransformation (pSADCFmlmtSVO) {
      console.log('■■■■■ MSPSA201M ■■■■■ (fn_CheckContentsTransformation) START ☞ ' + new Date().getTime())

      let lv_vm = this
      let pParams = {
        cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId,
        sADCFmlmtSVO: pSADCFmlmtSVO
      }
      const trnstId = 'txTSSSA22P4'
      const auth = 'S'


      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          if (lv_vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '') {
              // 서비스 호출 결과(body)가 있을 경우
              // 생성여부 확인 시도 회수를 증가
              // 20번까지만 생성확인 여부 시도
              lv_vm.lv_CheckDocsRetryNumberOfTimes++

              // FOM 파일 목록에 대한 생성여부를 결과 VO로 리턴
              // VO로 리턴된 FOM 파일 이외의 FOM파일은 계속 생성 여부를 확인
              if (response.body.sADCDoctTrfoBasSVO !== null && response.body.sADCDoctTrfoBasSVO.length > 0) {
                let tempPSADCFmlmtSVO = []
                let resultSADCDoctTrfoBasSVO = response.body.sADCDoctTrfoBasSVO.slice()

                // 생성요청 VO 목록에서
                lv_vm.lv_SADCFmlmtSVOList.forEach(dcItem => {
                  let tempFlag = false
                  let tempVO = {}

                  // 생성여부 확인된 VO 목록의 결과를 비교하여 일치하는 VO가 있을 경우
                  resultSADCDoctTrfoBasSVO.forEach(item => {
                    if (dcItem.srcFileNm01 === item.eltrnDoctId) {
                      tempFlag = true
                      tempVO = item
                    }
                  })

                  // 생성여부 확인 VO를 생성여부 확인된 VO만 별도로 저장하고 있는 VO에 저장
                  if (tempFlag) {
                    if (lv_vm.lv_CompletedPDFFileVOList !== null) {
                      let addFlag = true
                      lv_vm.lv_CompletedPDFFileVOList.forEach(item => {
                        if (tempVO.eltrnDoctId === item.eltrnDoctId) {
                          addFlag = false
                        }
                      })

                      if (addFlag) {
                        lv_vm.lv_CompletedPDFFileVOList.push(tempVO)
                      }
                    }
                  } else {
                    tempPSADCFmlmtSVO.push(dcItem)
                  }
                })

                // 생성여부 확인이 끝난 FOM 파일만 저장하고 있는 Array와 생성요청 FOM 파일 정보를 담고 있는 VO 목록이 일치하지 않으면
                // 생성여부 확인 작업을 계속 진행
                if (lv_vm.lv_CompletedPDFFileVOList.length !== lv_vm.lv_SADCFmlmtSVOList.length) {
                  // 20번 시도 회수보다 작으면 생성여부 확인 작업을 계속 시도
                  if (lv_vm.lv_CheckDocsRetryNumberOfTimes < 20) {
                    setTimeout(function () {
                      lv_vm.fn_CheckContentsTransformation(pParams.sADCFmlmtSVO)
                    }, 1000)
                  } else { // 그렇지 않으면 시도 중지
                    // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
                    //v_Vm.getStore('progress').getters.getState.isShow = false
                    // 조회이력 증가 호출
                    //lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                    //lv_vm.getStore('confirm').dispatch('ADD', 'PDF 변환에 실패하였습니다.')
                  }
                } else { // 그렇지 않으면 조회수 증가 단계로 이동
                  // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
                  //lv_vm.getStore('progress').getters.getState.isShow = false
                  // 조회이력 증가 호출
                  if (lv_vm.isNofacCnsl) { // [비대면상담]인 경우는 TUTU00012, TUTU00013 테이블에 상담 문서 정보 INSERT
                      for (let i = 0; i < pSADCFmlmtSVO.length; i++) {
                        let tempObj = {}
                        tempObj.nofacCnslDoctNm = pSADCFmlmtSVO[i].prdtNm
                        tempObj.eltrnDoctFilePathNm = response.body.sADCDoctTrfoBasSVO[i].eltrnDoctFilePathNm
                        tempObj.eltrnDoctFileNm = response.body.sADCDoctTrfoBasSVO[i].eltrnDoctFileNm
                        lv_vm.lv_SelectedPdfList.push(tempObj)
                      }
                      lv_vm.fn_InsNofacCnslData(lv_vm.lv_SelectedPdfList)
                  } else { // 조회이력 증가 호출
                    lv_vm.fn_IncrementItemInqrFgrList()
                  }
                }  
              } else if (lv_vm.lv_CheckDocsRetryNumberOfTimes < 20) {
                setTimeout(function () {
                  lv_vm.fn_CheckContentsTransformation(pParams.sADCFmlmtSVO)
                }, 1000)
              } else {
                // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
                lv_vm.getStore('progress').getters.getState.isShow = false
                // 조회이력 증가 호출
                // lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                lv_vm.getStore('confirm').dispatch('ADD', 'PDF 변환에 실패하였습니다.')
              }
            } else { // 서비스 호출 결과는 없지만
              // 변환 완료된 PDF 목록과 변환 요청 PDF 목록 개수가 일치하지 않을 경우 변화여부 확인 요청 시도
              if (lv_vm.lv_CompletedPDFFileVOList.length !== lv_vm.lv_SADCFmlmtSVOList.length) {
                setTimeout(function () {
                  lv_vm.fn_CheckContentsTransformation(pParams.sADCFmlmtSVO)
                }, 1000)
              }
            }
          }
        })
        .catch(function (error) {
          // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
          //lv_vm.getStore('progress').getters.getState.isShow = false
          window.vue.error(error)
        })
        console.log('■■■■■ MSPSA201M ■■■■■ (fn_CheckContentsTransformation) END ☞ ' + new Date().getTime())

    },
    /******************************************************************************
    * Function명 : fn_BtnESaveFile
    * 설명       : 단 건의 첨부파일을 실행(저장/오픈)한다.
    ******************************************************************************/
    fn_SaveFOMFile () {
      if (process.env.NODE_ENV === 'local') {
        let viewerTargetFileList = ''
        let savePdfTargetItemList = []
        if (this.lv_IsMadeMultiItem) {
          this.lv_SelectedItemList.forEach(selectedUntySlmtItem => {
            let itemObj = {}
            itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
            itemObj.eltrnDoctFileNm = 'test.pdf'
            itemObj.localFileNm = 'test.pdf'
            itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm

            savePdfTargetItemList.push(itemObj)
          })
        } else {
          let selectedUntySlmtItem = this.lv_SelectedSingleItem
          let itemObj = {}
          itemObj.eltrnDoctFilePathNm = selectedUntySlmtItem.pdfApndFilePathNm
          itemObj.eltrnDoctFileNm = 'test.pdf'
          itemObj.localFileNm = 'test.pdf'
          itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm

          savePdfTargetItemList.push(itemObj)
        }
        savePdfTargetItemList.forEach(item => {
          try {

            viewerTargetFileList += item.eltrnDoctFileNm + 'tssB' + item.fileTitle + 'tssA'
          } catch (error) {
            if (SpConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
          }
        })

        this.fn_CallPdfViewer(viewerTargetFileList)
      } else {
        let successCount = 0
        let tryCount = 0
        let savePdfTargetItemList = []
        let viewerTargetFileList = ''

        if (this.lv_IsMadeMultiItem === true) {
          this.lv_SelectedItemList.forEach(selectedUntySlmtItem => {
            if (selectedUntySlmtItem.pdfFileYn === 'Y') {
              let itemObj = {}
              itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
              itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm
              itemObj.eltrnDoctFileNm = selectedUntySlmtItem.pdfApndFilePathNm + '/' + selectedUntySlmtItem.pdfApndFileStoreNm
              itemObj.localFileNm = selectedUntySlmtItem.pdfApndFileStoreNm

              savePdfTargetItemList.push(itemObj)
            } else {
              let tempDcFmlmtSVO = null
              let tempCompletedPdfSVO = null
              this.lv_SADCFmlmtSVOList.forEach(dcFmlmtSVO => {
                if (selectedUntySlmtItem.untySlmtId === dcFmlmtSVO.untySlmtId) {
                  tempDcFmlmtSVO = dcFmlmtSVO
                }
              })

              if (tempDcFmlmtSVO !== null) {
                this.lv_CompletedPDFFileVOList.forEach(completedPDFFileSVO => {
                  if (tempDcFmlmtSVO.srcFileNm01 === completedPDFFileSVO.eltrnDoctId) {
                    tempCompletedPdfSVO = completedPDFFileSVO
                  }
                })
              }

              if (tempCompletedPdfSVO !== null) {
                let itemObj = {}
                itemObj.eltrnDoctFilePathNm = tempCompletedPdfSVO.eltrnDoctFilePathNm
                itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm

                // FOM 파일을 PDF로 변환했을 경우는 .pdf 확장자를 붙여서 파일을 다운받도록 설정
                let tempDateDir = tempCompletedPdfSVO.lstTrtDtm.slice(0, 10).replace(/-/g, '')
                if (tempCompletedPdfSVO.eltrnDoctId.indexOf('.pdf') < 0) {
                  itemObj.eltrnDoctFileNm = tempDateDir + '/' + tempCompletedPdfSVO.eltrnDoctId + '.pdf'
                  itemObj.localFileNm = tempCompletedPdfSVO.eltrnDoctId + '.pdf'
                } else {
                  itemObj.eltrnDoctFileNm = tempDateDir + '/' + tempCompletedPdfSVO.eltrnDoctId
                  itemObj.localFileNm = tempCompletedPdfSVO.eltrnDoctId
                }

                savePdfTargetItemList.push(itemObj)
              }
            }
          })
        } else {
          let selectedUntySlmtItem = this.lv_SelectedSingleItem
          if (selectedUntySlmtItem.pdfFileYn === 'Y') {
            let itemObj = {}
            itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
            itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm
            itemObj.eltrnDoctFileNm = selectedUntySlmtItem.pdfApndFilePathNm + '/' + selectedUntySlmtItem.pdfApndFileStoreNm
            itemObj.localFileNm = selectedUntySlmtItem.pdfApndFileStoreNm

            savePdfTargetItemList.push(itemObj)
          } else {
            let tempDcFmlmtSVO = null
            let tempCompletedPdfSVO = null
            this.lv_SADCFmlmtSVOList.forEach(dcFmlmtSVO => {
              if (selectedUntySlmtItem.untySlmtId === dcFmlmtSVO.untySlmtId) {
                tempDcFmlmtSVO = dcFmlmtSVO
              }
            })

            if (tempDcFmlmtSVO !== null) {
              this.lv_CompletedPDFFileVOList.forEach(completedPDFFileSVO => {
                if (tempDcFmlmtSVO.srcFileNm01 === completedPDFFileSVO.eltrnDoctId) {
                  tempCompletedPdfSVO = completedPDFFileSVO
                }
              })
            }

            if (tempCompletedPdfSVO !== null) {
              let itemObj = {}
              itemObj.eltrnDoctFilePathNm = tempCompletedPdfSVO.eltrnDoctFilePathNm
              itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm
              let tempDateDir = tempCompletedPdfSVO.lstTrtDtm.slice(0, 10).replace(/-/g, '')
              if (tempCompletedPdfSVO.eltrnDoctId.indexOf('.pdf') < 0) {
                itemObj.eltrnDoctFileNm = tempDateDir + '/' + tempCompletedPdfSVO.eltrnDoctId + '.pdf'
                itemObj.localFileNm = tempCompletedPdfSVO.eltrnDoctId + '.pdf'
              } else {
                itemObj.eltrnDoctFileNm = tempDateDir + '/' + tempCompletedPdfSVO.eltrnDoctId
                itemObj.localFileNm = tempCompletedPdfSVO.eltrnDoctId
              }

              savePdfTargetItemList.push(itemObj)
            }
          }
        }
        savePdfTargetItemList.forEach(item => {
          if (process.env.NODE_ENV === 'development') {
            item.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
            item.eltrnDoctFileNm = 'test.pdf'
            item.localFileNm = 'test.pdf'
            item.fileTitle = '[TEST]'
          }
          
          try {
            this.fn_FdpSaveFile(item)
              .then(response => {
                successCount++
                tryCount++
                
                viewerTargetFileList += response + 'tssB' + item.fileTitle + 'tssA'
                if (this.lv_IsMadeMultiItem) { // 다중 선택일 경우는 선택 파일 개수와 성공 및 시도 횟수가 일치할 경우 Viewer 실행
                  if (successCount === this.lv_SelectedItemList.length && successCount === tryCount) {
                    this.fn_CallPdfViewer(viewerTargetFileList)
                  } else if (tryCount === this.lv_SelectedItemList.length) {
                    // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                    //this.getStore('progress').getters.getState.isShow = false
                    this.$addSnackbar('총 ' + this.lv_SelectedItemList.length + '건 중 ' + (tryCount - successCount) + '건 저장이 실패하였습니다')
                  }
                } else { // 단일 선택일 경우는 바로 PDF Viewer 호출
                  this.fn_CallPdfViewer(viewerTargetFileList)
                }
              }).catch(error => {
                tryCount++
                // window.alert('파일 다운로드 실패 콜백 : ' + new Error(error))
                // 다중 선택일 경우
                if (this.lv_IsMadeMultiItem) {
                  // 시도 횟수와 선택한 파일 개수가 일치할 경우에만 오류 메시지 출력
                  if (tryCount === this.lv_SelectedItemList.length) {
                  // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                    this.getStore('progress').getters.getState.isShow = false
                    // 자료 선택 건수에 실패 건수를 오류 메시지로 출력
                    this.$addSnackbar('총 ' + this.lv_SelectedItemList.length + '건 중 ' + (tryCount - successCount) + '건 저장이 실패하였습니다')
                  }

                  if (SpConstants.DEBUG_MODE_CON) window.console.log('■■■■■ TSSSP201M ■■■■■ 다건 실패 : ' + new Error(error))
                } else { // 단일 선택에 오류 발생 시 시도 횟수는 비교하지 않음.
                  // 화면 깜박거림을 방지하기 위한 로딩바를 해제
                  this.getStore('progress').getters.getState.isShow = false
                  // 오류 팝업 메시지 출력
                  this.$addSnackbar('총 1건 중 1건 저장이 실패하였습니다')
                }
                if (SpConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
              })
          } catch (error) {
            if (SpConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
          }
        })
      }
    },
    
    /******************************************************************************
    * Function명 : fn_FdpSaveFile
    * 설명       : 첨부파일을 저장한다.
    ******************************************************************************/
    fn_FdpSaveFile (obj) {
      return new Promise((resolve, reject) => {
        // .pdf 확장자가 없을 경우 확장자 첨부
        if (obj.localFileNm.indexOf('.pdf') < 0) {
          obj.localFileNm = obj.localFileNm + '.pdf'
        }

        
        window.fdpbridge.exec('downloadPlugin', {
          path: obj.eltrnDoctFilePathNm,
          filename: obj.eltrnDoctFileNm, // src Directory + fileName
          type: 'pdf',
          localpath: '/sli/tss/comm/fmlmt', // local Directory(안내 자료는 fmlmt 폴더 사용)
          localfilename: obj.localFileNm, // local fileName
          rename: 'N'
        }, (result) => {
          resolve(result.data)
        }, (result) => {
          reject(result.data)
        })
      })
    },


    fn_CallPdfViewer (viewerTargetFileList) {
      // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
      this.getStore('progress').getters.getState.isShow = false
      let libraryItemCount = 0
      if (this.lv_IsMadeMultiItem) {
        this.lv_SelectedItemList.forEach(item => {
          if (item.untySlmtClsfNm01.indexOf(SpConstants.LIBRARY_CATE_NM) > -1 || item.untySlmtClsfNm01.indexOf(SpConstants.LOVEONHELP_CATE_NM) > -1) {
            libraryItemCount++
          }
        })
      } else {
        if (this.lv_SelectedSingleItem.untySlmtClsfNm01.indexOf(SpConstants.LIBRARY_CATE_NM) > -1 || this.lv_SelectedSingleItem.untySlmtClsfNm01.indexOf(SpConstants.LOVEONHELP_CATE_NM) > -1) {
          libraryItemCount++
        }
      }


      // 대분류명이 "라이브러리" 형태가 아닌 "(p)" 등이 끝에 붙어올수 있다. 이를 고려함.
      // lv_SelcectedClsfSytmNm 변수는 swipe 탭에서 "라이브러리" or "사랑On도움방" 탭을 선택후 pdf 뷰 보기를 할경우임.
      let menuAuth = ''
      if (!this.lv_SelcectedClsfSytmNm) this.lv_SelcectedClsfSytmNm = ''
      if (libraryItemCount > 0 || this.lv_SelcectedClsfSytmNm.indexOf('라이브러리') !== -1 || this.lv_SelcectedClsfSytmNm.indexOf('사랑On도움방') !== -1) { // 테블릿과 별도 구현, 동기화시 주의!!!!!
       menuAuth = '0000' // 안드로이드 pdf 뷰에서 "SMS" 메뉴 숨기기(다중 선택시 "라이브러리" or "사랑On도움방" 포함되면 선택된 모든 파일에 대해서도 보이지 않음)
      } else {
       menuAuth = '1111'
      }

      let lv_vm = this
      // PDF 뷰어 호출
      window.fdpbridge.exec('callPdfViewerPlugin', {
        path: '/sli/tss/comm/fmlmt',
        menu: menuAuth, // 대메뉴가 "라이브러리" or "사랑On도움방" 일때 안드로이드 앱의 pdf 뷰's "SMS" 메뉴는 않보이게 한다.
        file: viewerTargetFileList // 서버에 올릴때.
        // file: 'EST_20th.pdftssB제목1tssAtest1234567890.pdftssB제목2tssAtest.pdftssB제목3tssAEST_20th2.pdftssB제목4tssAtest-123.pdftssB제목5tssAEST_20th012345678901234567890123456789.pdftssB제목5tssA' // 테스트
      }, function (result) {
        // 성공콜백
        // 스마트폰은 SMS 팝업창을 바로 호출하자. 이때 btn1 이고 pdf뷰의 'X'표시를 클릭시는 'cancel' 값이 온다.
        if (result.data === 'btn1') {
          //this.lv_IsSelectedLibraryContent === true 체크해서 SMS발송 막아야함 pk.son
          // if (lv_vm.lv_IsSearch) lv_vm.fn_SMSBtnClicked('TSSSP205M')
          lv_vm.fn_SMSBtnClicked()   //문자
        } else if (result.data === 'btn2') {
                lv_vm.fn_OpenPopEmailCustList()  //이메일
        } else if (result.data === 'btn3') {
                lv_vm.fn_print_BottomSheet_open() //인쇄
        } else if (result.data === 'btn4') {
                lv_vm.fn_OpenMSPSA210P()          
        }
      }, function (result) {
        // 실패콜백
        if (SpConstants.DEBUG_MODE_CON) console.log('실패콜백=' + result.data)
      })
    },

    /*********************************************************
    * Function명: fn_SMSBtnClicked
    * 설명: TSSSP201M.TAB에서 "문자전송" 버튼 클릭시
    * Params:  N/A
    * Return: N/A
    *********************************************************/
    fn_SMSBtnClicked () {
      if (this.lv_SelectedItemList.length > 5) {
         this.$addSnackbar('선택 가능 자료는 최대 5개 입니다.')
         return
      }

      let lv_vm = this
      // 파라미터 설정
      let pParams = {}

      const trnstId = 'txTSSSA01S8'
      const auth = 'S'
      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
          if (lv_vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
              if (response.body.startDt !== undefined && response.body.startDt !== '') {
                if (new Date() >= new Date(response.body.startDt) && new Date() <= new Date(response.body.endDt)) {
                  //알럿띄워야됨 종료시키고 pk.son
                  lv_vm.$addSnackbar(response.body.msg)
                } else {
                  //lv_vm.fn_OpenPopCustList()
                  lv_vm.fn_OpenPopSmsCustList()  // 2차반영
                }
              }else{
                // 서버 config 키값이 정의 되어 있지 않더라도 SMS 고객선택 팝업 호출
                //lv_vm.fn_OpenPopCustList()
                lv_vm.fn_OpenPopSmsCustList()  // 2차반영
              }
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /*********************************************************
     * Function명: fn_OpenPopCustList
     * 설명: 안내자료_고객선택 수신 팝업오픈
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OpenPopCustList() {
      let lv_vm = this
      let itemList = []

      if(this.clickedSingleItem === true){
        itemList.push(this.lv_SelectedSingleItem)
      }else{
        itemList = this.lv_SelectedItemList
      }
      this.popData = this.$bottomModal.open(MSPSA203P,{
        properties: {
          paramData: itemList // param셋팅
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
          popupCloseMSPSA203P: (pData) => {
            if(pData !== ''){
              
              this.custListObj = [{targetType: 's', parentsViewId: 'MSPSA201M', custList: pData}]
              this.fn_OpenPopSmsSendList(itemList)
            }else{
              this.$bottomModal.close(lv_vm.popData)
            }
          }
        }
      })
    },


    /*********************************************************
    * Function명: fn_OpenPopCustList
    * 설명: 수신고객목록 팝업오픈
    * Params: N/A
    * Return: N/A
    *********************************************************/
    fn_OpenPopSmsSendList(itemList) {
      let lv = this
      this.popData1 = this.$bottomModal.open(MSPCM128P,{
        properties: {
          pChnlCustIds: this.custListObj, // param셋팅
          pSelectedUntySlmtItemList: itemList,
          pIsShowTSSSP128P: true
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
          onPopupClose: (pData) => {
            if (pData === 'confirm') {
              this.$bottomModal.close(lv.popData)
              this.lv_IsShowMSPCM203P = false
            }
            this.$bottomModal.close(lv.popData1)
          }
        }
      })
    },

    /* 2차 오픈시 추가반영   화면 하단 BS 영역부문 관련 프로그램반영   */

    /*********************************************************
     * Function명: fn_SaveToCouncel  (화상상담 자료저장)
     * 설명: 고객목록 팝업에서 고객 선택 후 저장 버튼 클릭 시
     * Params: 고객목록 팝업에서 선택된 고객목록(List<SAGrpInfoSVO>)
     * Return: N/A
     *********************************************************/
    fn_SaveToCouncel() {
     
     console.log('@@@ MSPSA201M >>>  화상상담자료저장 fn_SaveToCouncel START  ')

      this.isNofacCnsl = true

      let parentsViewId = 'MSPSA201M'
      let custList = this.lv_SelectedCustList.slice()
      this.lv_TargetType = 'c'
        // 수신고객목록에 전달할 선택 고객목록 및 기타 파라미터 설정
      this.custListObj = [
          {targetType: this.lv_TargetType, parentsViewId: parentsViewId, custList: custList}
      ]

      this.fn_AfterCustSearch() // FOM문서 PDF변환 혹은 PDF문서 정보 저장
    },
    /*********************************************************
     * Function명: fn_AfterCustSearch
     * 설명: [비대면화상상담]고객목록 팝업에서 고객 선택 후 다음 버튼 클릭 시
     * Params: 고객목록 팝업에서 선택된 고객목록
     * Return: N/A
     *********************************************************/
    fn_AfterCustSearch() {
      // 다중 선택 후 화상상담 진행시
      this.lv_IsMadeMultiItem = true

      this.lv_SelectedFomItemList = [] // PDF로 변환될 FOM 파일 저장 목록 초기화
      this.lv_SelectedPdfList = [] // 기존에 만들어진 PDF파일 담을 목록 초기화
      let lv_vm = this
      // 로딩바 강제 설정
      this.getStore('progress').getters.getState.isShow = true
      console.log('■■■■■ MSPSA201M ■■■■■ 화상상담자료저장 전 선택항목 체크(fn_AfterCustSearch) START ☞ ' + new Date().getTime())

      // 1. FOM 파일만 별도로 추출
      //    FOM 파일은 문서변화 서버를 이용해 PDF로 변환
      // 2. PDF 파일일 경우
      //    'UNTY_SLMT_DIR' 를 명시적으로 지정해 주고
      //    pdfApndFilePathNm에 저장된 'ic/20190219' 와
      //    pdfApndFileStoreNm에 저장된 '30002012030102301203213'와 같은 파일명을 조합하여 파일을 다운로드(.pdf 확장자를 붙이지 않음)
      //    -> 최종경로 : /filedown.do?path=UNTY_SLMT_DIR&filename=ic/20190220/000300336246415221102209102
      console.log('********** MSPSA201M ****** lv_SelectedCnslItemList ☞ ', this.lv_SelectedItemList)

      this.lv_SelectedItemList.forEach(item => {
        if (item.pdfFileYn === 'Y') { // PDF 파일
          // 로컬 테스트인 경우
          if (process.env.NODE_ENV === 'local') {
              let itemObj = {}
              itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
              itemObj.nofacCnslDoctNm = item.untySlmtNm
              itemObj.eltrnDoctFileNm = item.pdfApndFileId // test용
              lv_vm.lv_SelectedPdfList.push(itemObj)
          } else {
              let itemObj = {}
              itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR' // Context.properties에서 PDF 파일이 저장된 위치를 명시한 키값 설정
              itemObj.nofacCnslDoctNm = item.untySlmtNm
              // itemObj.eltrnDoctFileNm = item.pdfApndFileStoreNm // PDF 파일일 경우는 확장자 .pdf를 붙이지 않음
              itemObj.pdfFilePathDirNm = item.pdfApndFilePathNm // ic/20190219
              itemObj.eltrnDoctFileNm = item.pdfApndFileStoreNm
              lv_vm.lv_SelectedPdfList.push(itemObj)
          }
        } else {
             lv_vm.lv_SelectedFomItemList.push(item) // FOM 파일 저장
        }
      })

      // FOM 파일이 하나 이상 선택되었을 경우는 PDF 변환 진행
      if (lv_vm.lv_SelectedFomItemList.length > 0) {
          lv_vm.fn_CreateToPDFContnt()
      } else { // PDF문서만 선택 되었을 때
          lv_vm.fn_InsNofacCnslData(lv_vm.lv_SelectedPdfList)
      }
    },

    /******************************************************************************
    * Function명 : fn_InsNofacCnslData
    * 설명       : [비대면화상상담] 자료 저장
    * Params     : 비대면채널고객ID, 비대면상담문서ID, 비대면업무구분코드,비대면컨설턴트ID,문서삭제여부,비대면상담문서명,비대면상담문서경로명
    * Return     : 결과 코드(1:성공 0:실패)
    ******************************************************************************/
    fn_InsNofacCnslData(docInfo) {

      console.log('***** MSPSA201M *** PDF문서 저장 *** fn_InsNofacCnslData ☞ ', docInfo)
      let lv_vm = this
 
      lv_vm.isNofacCnsl = false // 초기화
      lv_vm.lv_TargetType = 'p' // 초기화
      let pParams = {}
      let ctNofacCnslListVO = []

      for (let i = 0; i < docInfo.length; i++) { // 문서 목록
        for (let j = 0; j < lv_vm.custListObj[0].custList.length; j++) { // 선택 고객 목록
          let pParam = {}
          pParam.nofacChnlCustId = lv_vm.custListObj[0].custList[j].chnlCustId // 비대면채널고객ID
          pParam.nofacCnslBusnScCd = 'SA' // 비대면업무구분코드(안내자료:SA)
          pParam.nofacCnsltNo = lv_vm.getStore('userInfo').getters.getUserInfo.userId // 비대면컨설턴트ID
          pParam.doctDeltYn = ' ' // 문서삭제여부
          pParam.nofacCnslDoctNm = docInfo[i].nofacCnslDoctNm // 비대면상담문서명
          pParam.nofacCnslDoctId = docInfo[i].eltrnDoctFileNm // 비대면상담문서ID
          pParam.nofacCnslDoctPathNm = docInfo[i].eltrnDoctFilePathNm // 비대면상담문서경로명
          pParam.pdfFilePathDirNm = docInfo[i].pdfFilePathDirNm // 기존PDF 파일 저장 경로명 예)ic/20190219
          ctNofacCnslListVO.push(pParam)
        }
      }

      pParams.ctNofacCnslListVO = ctNofacCnslListVO
      console.log('### MSPSA201M [비대면화상상담] 자료 저장 start>>' + pParams.ctNofacCnslListVO)

      const trnstId = 'txTSSCT80S1'
      const auth = 'S'

      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          if (lv_vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
              if (response.body.resultCd === 1) {
                if (lv_vm.custListObj[0].custList.length === 1) {    // 화상상담 고객이 2명 이상이면 화상상담 자료목록 팝업 호출 
                  lv_vm.lv_selCust = {}
                  lv_vm.lv_selCust.custNm = lv_vm.custListObj[0].custList[0].custNm
                  lv_vm.lv_selCust.chnlCustId = lv_vm.custListObj[0].custList[0].chnlCustId
                  let age = 0
                  let birthDate = lv_vm.custListObj[0].custList[0].dob
                  let contractDate = moment(new Date().toISOString()).format('YYYYMMDD')
                  let fullMonths = 0
                  if (parseInt(contractDate.substring(0, 4)) < 1988) {
                    fullMonths = DateUtil.getMonthPeriodBySingleCalc(birthDate, contractDate)
                  } else {
                    fullMonths = DateUtil.getPassedMonthCount(birthDate, contractDate)
                  } // end else if
                  let years = parseInt(fullMonths / 12)
                  let months = parseInt(fullMonths % 12)
                  if (months > 5) {
                    age = years + 1
                  } else {
                    age = years
                  } // end else if
                  lv_vm.lv_selCust.age = age + '세' // 보험나이
                  
                  lv_vm.fn_nofacCnslBtnClick() // 고객별 화상상담 목록 팝업 열기   
                } else {
                        console.log('### MSPSA201M [비대면화상상담] 자료 저장 end >>' )
                        // lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                        // lv_vm.getStore('confirm').dispatch('ADD', '비대면상담 자료 저장을 완료했습니다.')
                        lv_vm.fn_nofaeCnsl_tostMesg()                              // 비대면상담 자료 저장을 완료했습니다                      
                }
              }
            } else {
              //lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              lv_vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },           
 
    /*********************************************************
     * Function명: fn_OpenPopSmsCustList
     * 설명:  고객선택 수신 팝업오픈
     * Params: N/A
     * Return: N/A 
     *********************************************************/
    fn_OpenPopSmsCustList() {

      let lv_vm = this
      this.custListObj = []

     lv_vm.lv_Popup152 = lv_vm.$bottomModal.open(MSPCM152P, {
     properties: {
       pChnlCustIds: [
         {targetType: 's',
          parentsViewId: 'MSPSA201M',
          custList: []
         }
       ]
     },
     listeners: {
        onPopupClose: (pData) => {
        // 모달 닫기
        lv_vm.$bottomModal.close(lv_vm.lv_Popup152)
      },
      onNextService: (pData) => {
        // 모달 닫기
        lv_vm.$bottomModal.close(lv_vm.lv_Popup152)
        lv_vm.custListObj = [{targetType: 's', parentsViewId: 'MSPSA201M', custList: pData.selectList}] 
        lv_vm.fn_OpenMSPCM128P()
      },
    }
  })          

  },
    /*********************************************************
    * Function명: fn_OpenMSPCM128P
    * 설명: 수신고객목록 팝업오픈 (문자발송)
    * Params: N/A
    * Return: N/A
    *********************************************************/
    fn_OpenMSPCM128P: _.debounce(function () {
      let lv_vm = this
      let guidList = []
      // if(this.lv_SelectedItemList.length > 1){
      //   guidList = this.lv_SelectedItemList
      // }else{
      //   guidList.push(this.lv_SelectedItemList)   
      // }  
      
      if(this.clickedSingleItem === true){
        guidList.push(this.lv_SelectedSingleItem)
      }else{
        guidList = this.lv_SelectedItemList
      }


        console.log('lv_vm.custListObj >> ', lv_vm.custListObj)
        console.log('lv_vm.guidList >> ',  guidList)
        lv_vm.lv_Popup128 = lv_vm.$bottomModal.open(MSPCM128P,{
        properties: {
          pChnlCustIds: lv_vm.custListObj, // param셋팅
          pSelectedUntySlmtItemList: guidList , 
          pIsShowTSSSP128P: true
        },
        listeners: {
            // 팝업 닫히면서 실행되는 함수
           onPopupClose: (pData) => {
             lv_vm.$bottomModal.close(lv_vm.lv_Popup128)
           },
             // 단순 팝업 종료
           onPopupCancel: () => {
             lv_vm.$bottomModal.close(lv_vm.lv_Popup128)
           },         
      // 터치온 발송 프로세스 async
           onNextService: (pData) => {
              lv_vm.$bottomModal.close(lv_vm.lv_Popup128)
              lv_vm.custListObj = []
              lv_vm.custListObj = pData.rtnData
             // 터치온 팝업 오픈(MSPCM154P)
              lv_vm.fn_OpenMSPCM154P()
           }
        }
      })    
    }, 300),    

/*********************************************************
 * Function명: fn_OpenMSPCM154P
 * 설명: 터치On 문자발송 팝업 오픈
 * 
 *********************************************************/
 fn_OpenMSPCM154P: _.debounce(function () {

  let lv_vm = this

  lv_vm.lv_Popup154 = lv_vm.$bottomModal.open(MSPCM154P, {
    properties: {
      pPage: lv_vm.custListObj.parentsViewId,                   // 모화면 페이지명
      pSmsTargetCustList: lv_vm.custListObj.smsTargetCustList,  // 수신고객리스트
      pMsg: lv_vm.custListObj.msg,                              // 선택된 상용구
      pIsNotEditable: lv_vm.custListObj.isNotEditable           // 메시지 수정가능여부
    },
    listeners: {
      onPopupClose: (pData) => { 
        if (pData.str === 'confirm') {
          lv_vm.getStore('toast').dispatch('ADD', '발송되었습니다.')
        }
        // 모달 닫기
        lv_vm.$bottomModal.close(lv_vm.lv_Popup154)
      }
    }
  })
  } , 300),      


  /*********************************************************
   * Function명: fn_OpenPopEmailCustList
   * 설명:  고객선택 수신 팝업오픈-이메일
   * Params: N/A
   * Return: N/A 
   *********************************************************/
    fn_OpenPopEmailCustList() {

      if (this.lv_SelectedItemList.length > 5) {
           this.$addSnackbar('선택 가능 자료는 최대 5개 입니다.')
           return
      } 

      let lv_vm = this
      this.custListObj =  [
         {targetType: 'e',
          parentsViewId: 'MSPSA201M',
          custList: []
         }
       ]
      let selectPDFfile = []

      lv_vm.lv_popup152 = lv_vm.$bottomModal.open(MSPCM152P, {
      properties: {
      //   pChnlCustIds: [
      //    {targetType: 'e',
      //     parentsViewId: 'MSPSA201M',
      //     custList: []
      //    }
      //  ],
      pChnlCustIds: lv_vm.custListObj,
        pPDFfile : selectPDFfile
      },
      listeners: {
        onPopupClose: (pData) => {
          // 모달 닫기
          lv_vm.$bottomModal.close(lv_vm.lv_popup152)
        },
        onNextService: async (pData, selectPDFfile) => {
         // 중복제거
          let custList = []    // 문자수신 고객 list
          let cnt = 0
  
          if (pData.selectList.length > 0) {
            for (let i = 0; i < pData.selectList.length; i++) { 
              cnt = 0
              if (i === 0) {
                custList.push(pData.selectList[i])
              } else {
                for (let j = 0; j < custList.length; j++) {
                  if (pData.selectList[i].chnlCustId === custList[j].chnlCustId) {
                    cnt++
                  }
                }
                if (cnt === 0) {
                  custList.push(pData.selectList[i])
                }
              }
            }
          }

          lv_vm.custListObj = [{targetType: 'e', parentsViewId: 'MSPSA201M', custList: custList}]  
          selectPDFfile = lv_vm.lv_SelectedItemList
          let lv_pTitle = '삼성생명' 
          let lv_pText = '고객님 안녕하세요.\n' +
                          '삼성생명 ' + this.getStore('userInfo').getters.getUserInfo.userNm + '입니다.\n' +
                          '고객님께 도움이 될 만한 자료가 있어 보내드립니다.\n' +
                          '오늘도 즐거운 하루 되세요!'  
          let lv_pDataModify = true                
          if (lv_vm.custListObj[0].custList.length > 0) {
            // 모달 닫기
            await lv_vm.$bottomModal.close(lv_vm.lv_popup152)
            // 문자/이메일/DM/택배 클릭시 호출(이벤트 최종 목적지 배분)
            await lv_vm.$cmUtil.fn_OpenMSPCM191P(lv_vm, lv_vm.custListObj, selectPDFfile, lv_pTitle, lv_pText, lv_pDataModify)
          }
        },
      }
     })
    },
    /*********************************************************
     * Function명: fn_OzConfirmBtnClicked
     * 설명: 인쇄
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OzConfirmBtnClicked() {
      if (this.lv_SelectedItemList.length === 0) {  
         //this.getStore('confirm').dispatch('SETCOLOR', 'purple')
         this.getStore('confirm').dispatch('ADD', '자료를 선택하여 주십시요.')
      } else  { 
              this.pIsSelectedMultiItem = false
              this.selectItems = [] // 초기화
              this.selectItems = this.lv_SelectedItemList
              this.lv_SelectedItemListTmp = [] // 초기화
              // this.selectItems.forEach(item => {
              //     // My 세일즈북의 특정 그룹에 포함된 자료 VO에 없는 untySlmtClsfNm01를 강제로 설정
              //     // My 세일즈북의 자료를 상세보기 할 때, '라이브러리'라는 이름의 대 분류에 포함된 자료일 경우는 툴팁 메뉴를 비활성화 시켜주기 위해
              //    item.untySlmtClsfNm01 = item.untySlmtClsfNm                 
              // })
              this.lv_SelectedItemListTmp = this.selectItems.slice(0)              
              if(this.lv_SelectedItemListTmp.length > 1) {
                 this.pIsSelectedMultiItem = true
               }
               this.fn_OzPrintCntntList()
      }
    },      
    /*********************************************************
     * Function명: fn_OzConfirmBtnClicked()
     * 설명: 선택 자료 목록 인쇄 처리
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OzPrintCntntList() {
      console.log('@@@ MSPSA201M 인쇄  fn_OzPrintCntntList START...☞ ' + new Date().getTime())
      let lv_vm = this

      let tempUntySlmtItemList = []
      // 다중 선택일 경우 선택 자료 목록을 파라미터로 설정
      // if (lv_vm.pIsSelectedMultiItem) {
      //   tempUntySlmtItemList = lv_vm.lv_SelectedItemList
      // } else { // 단일 선택일 경우 선택 자료를 파라미터로 설정
      //   tempUntySlmtItemList.push(lv_vm.lv_SelectedItemList)
      // }
      tempUntySlmtItemList = lv_vm.lv_SelectedItemListTmp

      // 로컬 테스트용 코드
      if (process.env.NODE_ENV === 'local') {
        tempUntySlmtItemList.forEach(item => {
          item.pdfApndFileStoreNm = 'test_local.pdf'
          item.pdfApndFilePathNm = ''
        })
      }

      let pParams = {
        cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId,
        pblNos: lv_vm.lv_SpinValue,                 // 기본 1 매 설정 
        sACntntBasInfoSVO: tempUntySlmtItemList,
        cnsltInfoIcluYN: this.lv_IsIncludeCnsltInfo  // 컨설턴트 개인정보 이미지 미 포함으로 설정됨.
      }

      const trnstId = 'txTSSSA11P9'
      // const trnstId = 'txTSSSA11P1'
      const auth = 'S'
      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          if ( lv_vm.$commonUtil.isSuccess(response) ) { // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
            // 서비스 후처리
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
                  console.log('@@@ MSPSA201M   인쇄  fn_OzPrintCntntList this.post START ☞ ' + new Date().getTime())
              if (response.body.sACntntSVO.length === lv_vm.selectItems.length) {
              // 출력 건수가 선택된 아이템별 선택 매수와 동일할 경우
                  lv_vm.fn_printtostMesg()                              // 인쇄처리 완료 메세지  
                //lv_vm.$emit('evtCloseTSSSA011P', true)
              }
            } else { // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
             // lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              lv_vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          }
           console.log('@@@ MSPSA201M 인쇄  fn_OzPrintCntntList END ☞ ' + new Date().getTime())
        })
        .catch(function (error) {
          window.vue.error(error)
           console.log('@@@ MSPSA201M 인쇄  fn_OzPrintCntntList Error ☞ ' + new Date().getTime())
        })
    },            
   /*********************************************************
     * Function명: fn_OpenMSPSA210P
     * 설명:  스크랩팝업오픈
     * Params: N/A
     * Return: N/A this.lv_SelectedUntySlmtItems
     *********************************************************/
    fn_OpenMSPSA210P() {
            console.log("MSPSA201M-  fn_OpenMSPSA210M  start ")

      if (this.lv_SelectedItemList.length === 0) { 
         //this.getStore('confirm').dispatch('SETCOLOR', 'purple')
         this.getStore('confirm').dispatch('ADD', '자료를 선택하여 주십시요.')
         //this.fn_BottomSheet_open()
      } else {            
        this.selectItems = [] // 초기화
        this.selectItems = this.lv_SelectedItemList
         

        let lv_vm = this
        let itemList = []
        this.lv_Popup210 = this.$bottomModal.open(MSPSA210P,{
        properties: {
          pPage: 'MSPSA201M',
          pSelectedUntySlmtItems: this.selectItems.slice() // param셋팅
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
            popupCloseMSPSA210P: (pData) => {
                if(pData !== ''){              
                   lv_vm.$bottomModal.close(lv_vm.lv_Popup210)
                }else{
                   lv_vm.$bottomModal.close(lv_vm.lv_Popup210)
                }
            }
         }
        })
      } 
    }, 
   /*********************************************************
     * Function명: fn_OpenMSPSA222M
     * 설명:  화상상담팝업오픈
     * Params: N/A
     * Return: N/A this.lv_SelectedUntySlmtItems
     *********************************************************/
    fn_OpenMSPSA222P() {
            console.log("MSPSA201M-  fn_OpenMSPSA222P  start ")

      if (this.lv_SelectedItemList.length === 0) { 
         //this.getStore('confirm').dispatch('SETCOLOR', 'purple')
         this.getStore('confirm').dispatch('ADD', '자료를 선택하여 주십시요.')
         //this.fn_BottomSheet_open()
      } else {            
        this.selectItems = [] // 초기화
        this.selectItems = this.lv_SelectedItemList.slice()
         

        let lv_vm = this
        let itemList = []
        this.lv_Popup222 = this.$bottomModal.open(MSPSA222P,{
        properties: {
          pPage: 'MSPSA201M',
          pTargetType:  lv_vm.lv_TargetType,
          pSelectedCustList: [],
          pSearchCustNm: ''
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
            onPopupConfirm222P: (pData) => {
                if(pData !== ''){      
                  console.log("MSPSA201M-  onPopupConfirm222P  return1 " , pData)
                   lv_vm.lv_SelectedCustList = pData
                   lv_vm.fn_SaveToCouncel()
                   lv_vm.$bottomModal.close(lv_vm.lv_Popup222)                           
                }else{
                  console.log("MSPSA201M-  onPopupConfirm222P  return2 ")
                   lv_vm.lv_SelectedCustList = []
                   lv_vm.$bottomModal.close(lv_vm.lv_Popup222)
                }
            },
            onPopupClose222P: () => {
                   this.$bottomModal.close(this.lv_Popup222);
            }
         }
        })
      } 

    }, 
        

    /******************************************************************************
     * Function명 : fn_nofacCnslBtnClick
     * 설명       : 화상상담자료조회popup호출 - MSPSA063P
     ******************************************************************************/
    fn_nofacCnslBtnClick() {
              // 최대 30명까지 선택하도록 제한한다.
      const LIMITED_SMS_SELECTED = 30
      var lv_vm = this

      if (this.lv_SelectedCustList.length > LIMITED_SMS_SELECTED) {
          window.fdpbridge.exec('ToastPlugin', {message: '최대 30명까지만 문자발송 가능.\n총선택 인원을 확인해주세요.'}, () => {}, () => {})
      } else if (this.lv_SelectedCustList.length === 0) {
          window.fdpbridge.exec('ToastPlugin', {message: '선택 인원을 확인해주세요.'}, () => {}, () => {})
      } else {
        // 화상상담자료조회popup호출
          this.isMSPSA063P = this.$bottomModal.open(MSPSA063P, {
                properties: {
                  pNofacCnslCust: lv_vm.custListObj[0].custList[0]    //this.lv_SelectedCustList[0]    
                },
                listeners: {
                  onPopupConfirm: () => {
                    this.$bottomModal.close(this.isMSPSA063P);
                  },
                  onPopupClose: () => {
                    this.$bottomModal.close(this.isMSPSA063P);
                  }
                }
              },
              {
                properties: {
                  noHeader: true
                }
              })
      }        
    },  
    /*********************************************************
    * Function명: fn_print_BottomSheet_XXXX
    * 설명: 인쇄 처리용 버텀 시트 제어
    * Params: N/A
    * Return: N/A
    *********************************************************/
    open() { 
      // this.$refs.bs10.open(); 
    },
    close() { 
      // this.$refs.bs10.close(); 
    },     
    fn_nofaeCnsl_tostMesg(){
        //this.$refs.stackableSnackbar.show({text:'비대면상담 자료를 저장하였습니다.'});
        this.getStore('toast').dispatch('ADD', '비대면상담 자료를 저장하였습니다.')
        this.fn_print_BottomSheet_close()
    },      
    fn_printtostMesg(){
        //this.$refs.stackableSnackbar.show({text:'인쇄되었습니다.'});
        this.getStore('toast').dispatch('ADD', '인쇄되었습니다.')
        this.fn_print_BottomSheet_close()
    },      
    fn_print_BottomSheet_open() { 
      if (this.lv_SelectedItemList.length < 1) { 
          //this.getStore('confirm').dispatch('SETCOLOR', 'purple')
          this.getStore('confirm').dispatch('ADD', '인쇄할 자료를 선택하여 주십시요.')
      } else {
          this.close()
          this.$refs.print_bottomSheet.open();
      }    
    },
    fn_print_BottomSheet_close() { 
      this.$refs.print_bottomSheet.close();
      this.open()
    }, 

    /*********************************************************
     * Function명: fn_ScrollBefore
     * 설명: scroll 위치 복원
     *********************************************************/
    fn_ScrollBefore() {
      this.beforeScrollTop && this.$refs.activity.scrollTo(this.beforeScrollTop)
    },
    /******************************************************************************
    * Function명 : fn_GoldenRulePageMove
    * 설명       : 활동룰 포함 현황 조회 유무 페이지 이동
    ******************************************************************************/
    fn_GoldenRulePageMove(){
      console.log(" @@@@@@@@@@@  lv_gldnrActrExist >> "+this.lv_gldnrActrExist)
      if(this.lv_gldnrActrExist){
        console.log(" @@@@@@@@@@@  MSPAP308M >> ")
        this.$router.push({name: 'MSPAP308M', params:{prePageId:'MSPBC003M'}})
      }
    },
    /*********************************************************
     * Function명: fn_gldnrActrExist
     * 설명: 골든룰활동 컨설턴트 활동룰 포함 현황 조회
     *********************************************************/
    fn_gldnrActrExist(){
      let onpstOrgKndCd = this.getStore('userInfo').getters.getUserInfo.onpstOrgKndCd
      if(onpstOrgKndCd === '11' || onpstOrgKndCd === '20' || onpstOrgKndCd === '14' || onpstOrgKndCd === '13' || onpstOrgKndCd === '15'){
        this.lv_gldnrActrExist = true
      }
    }
  }
}
</script>
<style scoped>
</style>
